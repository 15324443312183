import orderBy from 'lodash/orderBy';
import { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, Typography, CardMedia, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// hooks
import useResponsive from '../../hooks/useResponsive';
import { useLocales } from '../../locales';
// contexts
import { useOnboardingContext } from '../../contexts/OnboardingContext';
// redux
import {
  useGetUserQuery,
  useGetUserUserCampaignsQuery,
  usePatchUserOnboardingMutation,
} from '../../redux/coreApi';
//
import Joyride, { CallBackProps, Placement } from 'react-joyride';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ONBOARDING_STEPS } from '../../constants';

// ----------------------------------------------------------------------
const NEXT_ONBOARDING_STEP_INDEX = {
  start: ONBOARDING_STEPS.campaigncard,
  campaigncard: ONBOARDING_STEPS.campaigndetails,
  campaigndetails: ONBOARDING_STEPS.campaignrequirements,
  campaignrequirements: ONBOARDING_STEPS.hashtagsandmentions,
  hashtagsandmentionsdesktop: ONBOARDING_STEPS.orders,
  hashtagsandmentions: ONBOARDING_STEPS.ordersMobileNavMenuOpen,
  orders: ONBOARDING_STEPS.uploadcontent,
  uploadcontentdesktop: ONBOARDING_STEPS.stats,
  uploadcontent: ONBOARDING_STEPS.statsMobileNavMenuOpen,
  stats: ONBOARDING_STEPS.statsstatistics,
  statsstatistics: ONBOARDING_STEPS.statsaudience,
  statsaudience: ONBOARDING_STEPS.statsgeography,
  statsgeographydesktop: ONBOARDING_STEPS.chat,
  statsgeography: ONBOARDING_STEPS.chatMobileNavMenuOpen,
  chat: ONBOARDING_STEPS.chatconversation,
  chatconversation: ONBOARDING_STEPS.finish,
  finish: ONBOARDING_STEPS.completed,
};

export default function Onboarding({ setOpen }: Readonly<{ setOpen: (open: boolean) => void }>) {
  const theme = useTheme();
  const {
    setState,
    state: { run, stepIndex, steps },
  } = useOnboardingContext();

  const isDesktop = useResponsive('up', 'lg');
  const tooltipSize = isDesktop ? 500 : 345;

  const { data: userInfo } = useGetUserQuery();

  useEffect(() => {
    if (userInfo) {
      let nextStep = -1;
      if (!userInfo.onboardingStep) {
        nextStep = ONBOARDING_STEPS.start;
      } else {
        nextStep =
          NEXT_ONBOARDING_STEP_INDEX[
            userInfo.onboardingStep as keyof typeof NEXT_ONBOARDING_STEP_INDEX
          ] ||
          NEXT_ONBOARDING_STEP_INDEX[
            `${userInfo.onboardingStep}${
              isDesktop ? 'desktop' : ''
            }` as keyof typeof NEXT_ONBOARDING_STEP_INDEX
          ];
      }

      if (nextStep < 0) {
        setState({ run: false });
      } else if (nextStep === 0 || nextStep > stepIndex) {
        setState({
          steps: steps.map((step) => ({ ...step, disableBeacon: true })),
          run: true,
          stepIndex: nextStep,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isDesktop]);

  const { translate } = useLocales();
  const navigate = useNavigate();

  const [campaignId, setCampaignId] = useState<string>();
  const { data = [], isLoading } = useGetUserUserCampaignsQuery();
  const [patchUserOnboarding] = usePatchUserOnboardingMutation();

  useEffect(() => {
    if (!isLoading && !!data?.[0]?.id) {
      const sortedData = orderBy(data, ['startDate'], ['desc']);
      setCampaignId(sortedData[0].id);
    }
  }, [data, isLoading]);

  useMount(() => {
    const stepObj = {
      start: {
        target: '#empty_space',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_start_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_start_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_start_body') }}
            />
          </Stack>
        ),
      },
      campaigncard: {
        target: '#campaign_card',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_campaign_card_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_campaign_card_subtitle')}
            </Typography>

            <Typography variant="body1" align="left">
              {translate('onboard_campaign_card_body')}
            </Typography>
          </Stack>
        ),
        placement: 'right' as Placement,
        disableBeacon: true,
      },
      campaigndetails: {
        target: '#campaign_details',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_campaign_details_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_campaign_details_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_campaign_details_body') }}
            />
          </Stack>
        ),
        placement: 'right' as Placement,
      },
      campaignrequirements: {
        target: '#campaign_requirements',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_campaign_requirements_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_campaign_requirements_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{
                __html: translate('onboard_campaign_requirements_body'),
              }}
            />
          </Stack>
        ),
        placement: 'bottom' as Placement,
      },
      hashtagsandmentions: {
        target: '#hashtags_and_mentions',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_campaign_hashtags_and_mentions_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_campaign_hashtags_and_mentions_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{
                __html: translate('onboard_campaign_hashtags_and_mentions_body'),
              }}
            />
          </Stack>
        ),
        placement: 'right' as Placement,
      },
      ordersMobileNavMenuOpen: {
        target: '#NavToggleButton',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_orders_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_orders_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_orders_body') }}
            />
          </Stack>
        ),
        placement: 'right' as Placement,
      },
      orders: {
        target: '#orders',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_orders_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_orders_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_orders_body') }}
            />
          </Stack>
        ),
        placement: (isDesktop ? 'right' : 'auto') as Placement,
      },
      uploadcontent: {
        target: '#empty_space',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_upload_content_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_upload_content_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_upload_content_body') }}
            />

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              sx={{ width: isDesktop ? 560 : 345, maxWidth: '100%' }}
            >
              <CardMedia
                component="iframe"
                src="https://www.youtube.com/embed/9lpWlGQ9WgU?si=1AG-YoTfdjD2i-cw"
                sx={{
                  width: isDesktop ? 560 : 345,
                  height: isDesktop ? 315 : 194,
                  maxWidth: '100%',
                }}
              />
            </Stack>
          </Stack>
        ),
      },
      statsMobileNavMenuOpen: {
        target: '#NavToggleButton',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_stats_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_stats_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_stats_body') }}
            />
          </Stack>
        ),
        placement: 'right' as Placement,
      },
      stats: {
        target: '#stats',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_stats_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_stats_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_stats_body') }}
            />
          </Stack>
        ),
        placement: (isDesktop ? 'right' : 'auto') as Placement,
      },
      statsstatistics: {
        target: '.stats_statistics',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_stats_statistics_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_stats_statistics_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_stats_statistics_body') }}
            />
          </Stack>
        ),
        placement: 'top' as Placement,
      },
      statsaudience: {
        target: '.stats_audience',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_stats_audience_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_stats_audience_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_stats_audience_body') }}
            />
          </Stack>
        ),
        placement: 'right' as Placement,
      },
      statsgeography: {
        target: '.stats_geography',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_stats_geography_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_stats_geography_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_stats_geography_body') }}
            />
          </Stack>
        ),
        placement: 'right' as Placement,
      },
      chatMobileNavMenuOpen: {
        target: '#NavToggleButton',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_chat_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_chat_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_chat_body') }}
            />
          </Stack>
        ),
        placement: 'right' as Placement,
      },
      chat: {
        target: '#chat',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_chat_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_chat_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_chat_body') }}
            />
          </Stack>
        ),
        placement: (isDesktop ? 'right' : 'auto') as Placement,
      },
      chatconversation: {
        target: '.chat_conversation',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_chat_conversation_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_chat_conversation_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_chat_conversation_body') }}
            />
          </Stack>
        ),
        placement: 'right' as Placement,
      },
      finish: {
        target: '#empty_space',
        content: (
          <Stack spacing={1}>
            <Typography variant="h3" align="center" sx={{ mb: 3 }}>
              {translate('onboard_finish_title')}
            </Typography>

            <Typography variant="h5" align="left">
              {translate('onboard_finish_subtitle')}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              dangerouslySetInnerHTML={{ __html: translate('onboard_finish_body') }}
            />
          </Stack>
        ),
      },
    };

    setState({ steps: Object.values(stepObj) });
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index, action, type } = data;

    if (action === 'close') {
      patchUserOnboarding({ body: { onboardingStep: 'completed' } });
      setState({ run: false });
      return;
    }

    if (action === 'next' && type === 'step:after') {
      switch (index) {
        case ONBOARDING_STEPS.start:
          patchUserOnboarding({ body: { onboardingStep: 'start' } });
          setState({ stepIndex: ONBOARDING_STEPS.campaigncard });
          break;

        case ONBOARDING_STEPS.campaigncard:
          patchUserOnboarding({ body: { onboardingStep: 'campaigncard' } });
          if (campaignId) {
            navigate(PATH_DASHBOARD.shop.view(campaignId));
            // wait some seconds to let the page load images
            setTimeout(() => {
              setState({ stepIndex: ONBOARDING_STEPS.campaigndetails });
            }, 2000);
          } else {
            setState({ run: false });
          }
          break;

        case ONBOARDING_STEPS.campaigndetails:
          patchUserOnboarding({ body: { onboardingStep: 'campaigndetails' } });
          setState({ stepIndex: ONBOARDING_STEPS.campaignrequirements });
          break;

        case ONBOARDING_STEPS.campaignrequirements:
          patchUserOnboarding({ body: { onboardingStep: 'campaignrequirements' } });
          setState({ stepIndex: ONBOARDING_STEPS.hashtagsandmentions });
          break;

        case ONBOARDING_STEPS.hashtagsandmentions:
          patchUserOnboarding({ body: { onboardingStep: 'hashtagsandmentions' } });
          if (isDesktop) {
            setState({ stepIndex: ONBOARDING_STEPS.orders });
          } else {
            setState({ stepIndex: ONBOARDING_STEPS.ordersMobileNavMenuOpen });
          }
          break;

        case ONBOARDING_STEPS.ordersMobileNavMenuOpen:
          setOpen(true);
          setState({ stepIndex: ONBOARDING_STEPS.orders });
          break;

        case ONBOARDING_STEPS.orders:
          patchUserOnboarding({ body: { onboardingStep: 'orders' } });
          navigate(PATH_DASHBOARD.orders);
          setState({ stepIndex: ONBOARDING_STEPS.uploadcontent });
          break;

        case ONBOARDING_STEPS.uploadcontent:
          patchUserOnboarding({ body: { onboardingStep: 'uploadcontent' } });
          if (isDesktop) {
            setState({ stepIndex: ONBOARDING_STEPS.stats });
          } else {
            setState({ stepIndex: ONBOARDING_STEPS.statsMobileNavMenuOpen });
          }
          break;

        case ONBOARDING_STEPS.statsMobileNavMenuOpen:
          setOpen(true);
          setState({ stepIndex: ONBOARDING_STEPS.stats });
          break;

        case ONBOARDING_STEPS.stats:
          patchUserOnboarding({ body: { onboardingStep: 'stats' } });
          navigate(PATH_DASHBOARD.socialNetworks);
          setState({ run: false });
          setTimeout(() => {
            setState({ run: true, stepIndex: ONBOARDING_STEPS.statsstatistics });
          }, 1000);
          break;

        case ONBOARDING_STEPS.statsstatistics:
          patchUserOnboarding({ body: { onboardingStep: 'statsstatistics' } });
          setState({ stepIndex: ONBOARDING_STEPS.statsaudience });
          break;

        case ONBOARDING_STEPS.statsaudience:
          patchUserOnboarding({ body: { onboardingStep: 'statsaudience' } });
          setState({ stepIndex: ONBOARDING_STEPS.statsgeography });
          break;

        case ONBOARDING_STEPS.statsgeography:
          patchUserOnboarding({ body: { onboardingStep: 'statsgeography' } });
          if (isDesktop) {
            setState({ stepIndex: ONBOARDING_STEPS.chat });
          } else {
            setState({ stepIndex: ONBOARDING_STEPS.chatMobileNavMenuOpen });
          }
          break;

        case ONBOARDING_STEPS.chatMobileNavMenuOpen:
          setOpen(true);
          setState({ stepIndex: ONBOARDING_STEPS.chat });
          break;

        case ONBOARDING_STEPS.chat:
          patchUserOnboarding({ body: { onboardingStep: 'chat' } });
          navigate(PATH_DASHBOARD.chat.root);
          setState({ run: false });
          setTimeout(() => {
            setState({ run: true, stepIndex: ONBOARDING_STEPS.chatconversation });
          }, 1500);
          break;

        case ONBOARDING_STEPS.chatconversation:
          patchUserOnboarding({ body: { onboardingStep: 'chatconversation' } });
          setState({ stepIndex: ONBOARDING_STEPS.finish });
          break;

        case ONBOARDING_STEPS.finish:
          patchUserOnboarding({ body: { onboardingStep: 'finish' } });
          navigate(PATH_DASHBOARD.root);
          setState({ run: false });
          setTimeout(() => {
            setState({ run: true, stepIndex: ONBOARDING_STEPS.completed });
          }, 1000);
          break;

        case ONBOARDING_STEPS.completed:
          patchUserOnboarding({ body: { onboardingStep: 'completed' } });
          setState({ run: false });
          break;

        default:
          break;
      }
    }
  };

  return (
    <>
      {process.env.REACT_APP_NODE_ENV === 'development' && (
        <Button
          variant="contained"
          size="small"
          onClick={() => patchUserOnboarding({ body: { onboardingStep: '' } })}
          sx={{ position: 'fixed', top: 60, right: 20, zIndex: 1200 }}
        >
          Reset Onboarding
        </Button>
      )}
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        disableOverlayClose
        disableCloseOnEsc
        hideBackButton
        styles={{
          options: {
            primaryColor: theme.palette.primary.main,
            arrowColor: [
              ONBOARDING_STEPS.start,
              ONBOARDING_STEPS.finish,
              ONBOARDING_STEPS.uploadcontent,
            ].includes(stepIndex)
              ? 'transparent'
              : 'white',
          },
          spotlight: {
            background: [
              ONBOARDING_STEPS.start,
              ONBOARDING_STEPS.finish,
              ONBOARDING_STEPS.uploadcontent,
            ].includes(stepIndex)
              ? 'transparent'
              : 'gray',
          },
          tooltip: {
            width:
              [ONBOARDING_STEPS.uploadcontent].includes(stepIndex) && isDesktop ? 600 : tooltipSize,
          },
        }}
      />
      <Stack
        id="empty_space"
        sx={{
          position: 'fixed',
          margin: 'auto',
          bottom: [ONBOARDING_STEPS.start, ONBOARDING_STEPS.finish].includes(stepIndex)
            ? '50%'
            : '50px',
          marginBottom: [ONBOARDING_STEPS.start, ONBOARDING_STEPS.finish].includes(stepIndex)
            ? '-150px'
            : 0,
          width: 0,
          maxWidth: 0,
          left: 0,
          right: 0,
          display:
            run &&
            [
              ONBOARDING_STEPS.start,
              ONBOARDING_STEPS.finish,
              ONBOARDING_STEPS.uploadcontent,
            ].includes(stepIndex)
              ? 'flex'
              : 'none',
        }}
      />
    </>
  );
}
